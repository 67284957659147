<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
    :section="field.section"
  >
    <v-text-field
      v-model.lazy="input"
      v-money="money"
      v-bind="field.props"
      prefix="RM "
      box
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  data () {
    return {
      input: '',
      money: {},
    }
  },
  watch: {
    input: function (val) {
      this.field.value = val.replace(/ /g, '')
    },
  },
}
</script>
